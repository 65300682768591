.header {
  margin-top: 125px;
  background: url("Rectangle 5372.png");
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center; 
  padding: 5% 0;
}
.max_width{
  max-width: 600px;
  margin: 0 auto;
  text-align: center;
}
.max_width .sub_title{
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 27px;
  text-align: center;
  color: #636365;
  
}
.pageitle h1{
  font-family: "Roboto", sans-serif;
  font-size: 40px;
  font-weight: 500;
  line-height: 50px;
  letter-spacing: 0;
  margin-bottom: 0px;
  margin-top: 0;
  color: #ffffff;
}
.pageitle p{
  color: #fff;
  z-index: 10;    
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 27px;
  text-align: center;
  margin: 0;
}
.pdsec{
  padding: 50px 0;
}
.catcont h3{
  font-weight: 500;
  font-size: 32px;
  line-height: 40px;
  text-align: left;
  margin: 0;
  margin-bottom: 10px;
  padding-bottom: 10px;
  font-family: "Roboto", sans-serif;
}

.sec_title {
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  font-size: 32px;
  line-height: 40px;
  text-transform: capitalize;
  color: #1c1c1c;
  margin: 0px; 
}

.sub_title {
  letter-spacing: 0.00938em;
  color: #1C1C1C;
  z-index: 10;
  font-weight: 400;
  font-family: "Roboto", sans-serif;
  line-height: 26px;
  margin-bottom: 20px;
  text-transform: capitalize;
  font-size: 20px;
  margin-top: 0;
}

.withor {
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 27px;
  text-align: center;
  color: #636365;
}
.text_left{
  text-align: left;
}
.text_center {
  text-align: center;
}

.title_image {
  max-width: 110px;
}

.img_fluid {
  max-width: 100%;
  height: auto;
}
.catcont p{
  color: #636365;
  font-family: "Roboto", sans-serif;
  font-size: 15px;
  font-weight: 400;
  line-height: 25px;
  opacity: .8;
  text-align: left;
    margin-top: 0;
}

.bold {
  font-weight: bold;
  text-decoration: none;
  color: white;
}

.main__content {
  padding:50px 0;
}
.phtree{
  font-size: 23px;
  font-weight: 400;
  line-height: 26.04px;
  text-align: left;
  margin: 0;
  margin-bottom: 10px;
  font-family: "Roboto", sans-serif;
}
.searchbarDiv{
  padding-bottom: 20px;
}
.headergrid_img {
  width: 80%;
  margin-left: 9%;
  border: 1px solid black;
}

.searchbarDiv {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.artworks__heading {
  padding-left: 3%;
  padding-right: 3.5%;
  margin-top: 5%;
  margin-bottom: 5%;
}

.artheadingdivider {
  color: #e7ebed;
  margin-bottom: 1%;
}

.cardImage {
  width: 100%;
  height: 300px;
  /* object-fit: contain; */
  object-fit: fill;
}

.card__middle {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.card__middleRight {
  display: flex;
  margin-bottom: 6.5%;
}

.verticalLine {
  border-left: 1.5px solid grey;
  height: 2.75vw;
}

.buy__text {
  margin-top: -8%;
}

.rent__text {
  margin-top: -8%;
}

.print__text {
  margin-top: -8%;
}

.ProductCards {
  margin-left: -0.5%;
  width: 100%;
}

.card__main {
  margin-bottom: 20%;
}

.artwork__div {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding: 0% 7%;
}

.bottom__text {
  font-size: 0.6rem;
  font-weight: 500;
  font-family: "Roboto", sans-serif;
}

.pic_info {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 8px;
}
.card__top {
  position: relative;
  height: 350px;
  max-height: 350px;
  min-height: 350px;
  overflow: hidden;
  border: 1px solid #f1f7fd;
}
.card__top img {
  object-fit: fill;
  height: 100%;
}
.pic_info {
  display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 8px;
      margin-bottom: 7px;
      text-align: center;
      overflow: hidden;
}
.pic_info  .card__middle{
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  position: relative;
  display: block;
}
.titles {
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 23px;
  color: #1c1c1c;
  text-transform: capitalize;
}
.info_like {
  display: flex;
  justify-content: center;
  column-gap: 8px;
  align-items: center;
}
.wishlst{
  width: 25px;
  height: 25px;
  background: #f3f3f3;
  border-radius: 50%;
  line-height: 25px;
  text-align: center;
}
.like_count {
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  font-size: 15px;
  line-height: 23px;
  color: #1c1c1c;
}
.names {
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  font-size: 16px;
  line-height: 21px;
  color: #636365;
  opacity: 0.5;
  margin: 0;
  padding-top: 1px;
  padding-bottom: 6px;
  border-bottom: 1px solid #BDBDBD;
  text-transform: capitalize;
}
.price_sec{
  display: flex;
  justify-content: space-around;
  align-items: flex-start;
  margin-top: 8px;
  flex-wrap: wrap;
  width: 100%;
  margin-bottom: 7px;
  text-align: center;
}
.buys {
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 21px;
  color: #636365;
  opacity: 0.6;
  margin-bottom: 5px;
  display: block;
}

.prices {
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 21px;
  color: #1c1c1c;
}
.hrs {
  height: 50px;
  width: 0.1px;
  border-right: 1px solid #bdbdbd;
}
.cataloglist{
  margin-bottom: 15px;
}
.cataloglist a{
  text-decoration: none;
}
.catlogname{
  margin: 6px 0 5px;
  text-transform: capitalize;
  font-family: "Roboto", sans-serif;
  font-size: 17px;
  font-weight: 600;
  line-height: 24px;
  text-align: left;
  color: #1C1C1C;
  text-decoration: none;
}
.sec_scexcl {
  background: #F6F6F6;
  padding: 50px 0;
}
.timeline{
  padding: 0;
  margin-bottom: 0;
  margin-top: 30px;
  list-style-type: none;
  position: relative;
  counter-reset: timeline-counter;
}
.timeline li{
  padding-left: 75px;
  margin-top: 0;
  counter-increment: timeline-counter;
  position: relative;
}
.timeline li::before{
  content: counter(timeline-counter);
  width: 55px;
  height: 55px;
  border-radius: 50%;
  background: #fff;
  text-align: center;
  color: #1c1c1c;
  font-family: "Roboto", sans-serif;
  font-size: 23px;
  font-weight: 600;
  line-height: 55px;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 99;
  box-shadow: 0px 6.14px 19.66px 0px #080F340F;
}
/* .timeline li::after{
  content: '';
  height: 100%;
  width: 1px;
  background: #1c1c1c;
  position: absolute;
  top: 20%;
  left: 4.4%;
} */
.timeline li:last-child:after{
  display: none;
}
.whibg .timeline li::before{
  box-shadow: 0px 6.14px 19.66px 0px #080f3426;
}

.stepli{
  margin-bottom: 20px;
}
.stepli h4{
  margin: 0 0 5px;
  text-transform: capitalize;
  font-family: "Roboto", sans-serif;
  font-size: 18px;
  font-weight: 600;
  line-height: 24px;
  text-align: left;
  color: #1C1C1C;
}
.stepli p{
  font-family: "Roboto", sans-serif;
   font-size: 15px;
   font-weight: 400;
   line-height: 25px;
   text-align: left;
   color: #636365;
   opacity: .8;
   margin: 0;
}
.mt2{
  margin-top: 25px;
}
@media(max-width:767px){
  .mobalign{
    text-align: center;
  }
  .mobalign .text_left{
    text-align: center;
  }
  .catlogname{
    text-align: center;
  }
  .searchbarDiv{
    display: block;
  }
  .header{
    margin-top: 57px;
  }
  .pageitle h1{
    font-size: 25px;
    line-height: 26px;
  }
  .card__main{
    margin-bottom: 3%;
  }
}
