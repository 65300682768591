.proddets__bottom {
  padding: 50px 0;
  background-color: #f7f7f8;
}
.separator{
  margin: 0 5px;
  font-weight: bold;
}
.otherartistcard {
  background-color: white;
  padding: 13px;
  box-shadow: 0px 0px 7.24px rgba(0, 0, 0, 0.06);
  position: relative;
}
.otherartistcardinfo h4{
  font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 23px;
    color: #1c1c1c;
    text-transform: capitalize;
    margin: 0;
}
.otherartistprice h5{
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 21px;
  color: #636365;
  opacity: 0.6;
  margin: 0;
}
.otheim{
  height: 200px;
  max-height: 200px;
  min-height: 200px;
  overflow: hidden;
}
.othercardimage {
  width: 100%;
  object-fit: fill;
  height: 100%;
}
.otherartistdescr {
  padding-left: 15px;
  border-left: 1px solid #e4e3ea;
}
.otherartistdescr p{
  font-family: "Roboto", sans-serif;
  text-align: left;
  font-size: 15px;
  font-weight: 400;
  margin: 0;
  line-height: 25px;
  color: #636365;
  opacity: .8;
}
.otherartistimage {
  position: absolute;
  bottom: 25%;
  left: 0;
  right: 0;
  margin: 0 auto;
  text-align: center;
  width: 55px;
  height: 55px;
  border-radius: 50%;
  overflow: hidden;
  border: 3px solid #fff;
}
.verticalLine {
  border: 1px solid rgb(73, 71, 71);
  height: auto;
}
.otherartistcardinfo {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 40px;
}
.ellipseten { 
  cursor: pointer;
  max-width: 100%;
  height: 100%;
  width: 100%;
}
.otherartistchat {
  display: flex;
  align-items: center;
  gap: 6px;
}
.otherartistprice {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 10px;
}
.ellipsetag {
  border-radius: 50px;
  width: 100px;
  padding: 5px 0px 5px 0;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
}
.tagcontainer {
  margin-top: 2%;
  width: 100%;
}
.otherartistImg {
  width: 75px;
  border: 6px solid #dcdada;
  cursor: pointer;
  height: 75px;
  border-radius: 50%;
}
.otherartistinfo {
  display: flex;
  align-items: center;
  gap: 10px;
}
.starrating4 {
  width: 13%;
  margin-left: 2%;
}
.pd_like {
  text-align: center;
  justify-content: center;
  justify-items: center;
  gap: 10px;
  color: #636365 !important;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  align-self: center !important;
  align-items: center;
  /* font-size: 15px !important; */
}

.verticalLine_pro {
  border: 1px solid #636365;
  height: 20px;
}
.loc {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}
.loc p{
  color: #1C1C1C;
  display: flex;
  align-items: center;
}
.loc p span{
  margin-left: 5px;
}
.folbtn{
  font-family: "Roboto", sans-serif;
  color: #636365;
  border: 1px solid #636365;
  background: transparent;
  padding: 10px 23px;
  border-radius: 6px;
  cursor: pointer;
  font-size: 16px;
  font-weight: 500;
}
.folbtn:hover{
  background: #1C1C1C;
  border-color: #1C1C1C;
  color: #fff;
}
.loader {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 20px auto;
}
.artistinfo{
  text-align: left;
}
.artisname{
  font-family: "Roboto", sans-serif;
  font-size: 18px;
  font-weight: 500;
  line-height: 25.08px;
  text-align: left;
  text-decoration-line: underline;
  text-decoration-style: solid;
  color: #14142B;
  padding-bottom: 0px;
  display: block;
  text-transform: capitalize;
}
.stratinglst{
  list-style-type: none;
  padding: 0;
  margin: 0 0;
  padding-bottom: 2px;
}
.stratinglst li{
  display: inline-block;
  margin-right: 2px;
}
.stratinglst li:last-child{
  margin-right: 0;
}
.sticon{
  color: #2C2844;
  opacity: .2;
  font-size: 15px;
}
.artistinfo p{ 
    font-size: 14px;
    font-weight: 600;
    line-height: 21px;
    text-align: left;
    text-decoration-line: underline;
    text-decoration-style: solid;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    margin: 0;
    padding-bottom: 6px;
}
.arstlik{
  list-style-type: none;
  padding: 0;
  margin: 0;
  padding-top: 6px;
}
.arstlik li{
  display: inline-block;
  margin-right: 7px;
  padding-right: 7px;
  border-right:2px solid #c5c5c6;
  color: #1C1C1C;
  cursor: pointer;
}
.arstlik li:last-child{
  border-right:0 ;
  padding-right: 0;
  margin-right: 0;
}
.icst{
  font-size: 18px;
}
.otherarstwok{
  padding-top: 50px;
}
.otherarstwok h3{
  font-family: "Roboto", sans-serif;
    font-size: 32px;
    font-weight: 500;
    line-height: 45px;
    text-align: left;
    margin: 0;
    margin-bottom: 10px;
}
.keywordsec{
  padding: 50px 0 0;
}
.keywordsec h3{
  font-family: "Roboto", sans-serif;
  font-size: 32px;
    font-weight: 500;
    line-height: 45px;
  text-align: left;
  margin: 0;
  margin-bottom: 10px;
}
.keywlist{
  padding: 0;
  margin: 0;
  list-style-type: none;
}
.keywlist li{
  padding: 10px 25px;
  background: #fff;
  display: inline-block;
  margin: 0;
  margin-right: 5px;
  border-radius: 20px;
  text-transform: capitalize;
  color: #636365;
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 18.23px;
  text-align: center;
  margin-bottom: 7px;
}
.keywlist li:last-child{
  margin-right: 0;
}
.readMoreBtn{
  background: none;
    cursor: pointer;
    padding: 0;
    font-family: "Roboto", sans-serif;
    font-size: 15px;
    font-weight: 500;
    line-height: 25px;
    text-align: left;
    color: #1c1c1c;
    padding-top: 6px;
    border: 0;
}
@media(max-width:767px){
  .otherartistdescr {
    padding-left: 0px;
    border-left: 0;
    border-top: 1px solid #c5c5c6;
    padding-top: 15px;
  }
  .otherarstwok h3, .keywordsec h3, .qnasection h3{
    font-size: 23px;
    line-height: 26px;
    text-align: center;
  }
  .keywlist{
    text-align: center;
  }
}