.artisprofile {
  width: 69%;
  padding: 50px;
}

.loading_div {
  width: 69%;
}

.flex {
  display: flex;
  justify-content: center;
  column-gap: 50px;
  align-items: flex-start;
}

.download_csv {
  padding: 0px 20px;
}

.react-tel-input form-control {
  position: relative;
  font-size: 14px;
  letter-spacing: 0.01rem;
  margin-top: 0;
  margin-bottom: 0;
  padding-left: 0 !important;
  padding-top: 0px !important;
  margin-left: 0;
  background: #cacaca;
  border: 1px solid #cacaca;
  border-radius: 5px;
  line-height: 25px;
  height: 53px;
  width: 100%;
  outline: none;
  box-sizing: none;
  display: flex;
  flex-wrap: wrap;
}

.form-control {
  position: relative;
  display: block;
  width: 100%;
  padding: .375rem .75rem;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.5;
  opacity: .9;
  color: #636365;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: .25rem;
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  max-width: -webkit-fill-available;
  font-family: "Roboto", sans-serif;
  height: auto;
  outline: none !important;
  box-shadow: none !important;
}
.form-control:focus{
  border-color: #ced4da !important;
  outline: none !important;
  box-shadow: none !important;
  opacity: .9;
  color: #636365 !important;
}
select.form-control {
  margin-top: 0;
}

.form-group {
  display: block;
  margin-bottom: 15px;
}

.form-group label, label {
  margin-bottom: 0;
  display: block;
  margin-top: 0 !important;
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 23px;
  text-align: left;
}

.react-tel-input .form-control {
  width: 100% !important;
  padding: .375rem .75rem;
  font-size: 14px !important;
  line-height: 1.5 !important;
  letter-spacing:0 !important;
  font-weight: 400;
  color: #212529;
  border: 1px solid #ced4da !important;
  height: auto !important;
  font-family: "Roboto", sans-serif;
}

.react-tel-input .flag-dropdown {
  background-color: transparent !important;
  border: 1px solid #ced4da !important;
}

/* @media (min-width: 320px) and (max-width: 426px) {
  .form-control {
    height: 54px !important;
    width: 70% !important ;
    background-color: #f6f6f6 !important;
  }
}

@media (min-width: 426px) and (max-width: 769px) {
  .form-control {
    height: 54px !important;
    width: 78% !important ;
    background-color: #f6f6f6 !important;
  }
}

@media (min-width: 769px) and (max-width: 1024px) {
  .form-control {
    height: 54px !important;
    width: 84% !important ;
    background-color: #f6f6f6 !important;
  }
} */

/* Hide the Google Translate banner */
body .goog-te-banner-frame {
  display: none !important;
}

/* Hide the Google Translate logo and "Powered by" text */
body .goog-te-gadget {
  display: none !important;
}

/* Hide the Google Translate widget container if it’s duplicated */
body .goog-te-menu-frame {
  display: none !important;
}

/* Hide specific elements with class names */
body .goog-te-menu2 {
  display: none !important;
}

/* Hide the "Select Language" dropdown text */
body .goog-te-combo {
  display: none !important;
}

/* Hide specific "Powered by Google Translate" text */
body .goog-te-gadget-simple {
  display: none !important;
}
.text-center{
  text-align: center;
}
.goog-logo-link {
  display: none !important;
}

.goog-te-banner-frame {
  display: none !important;
  margin-top: -20px;
}

.translated-ltr {
  margin-top: -40px;
}

.translated-ltr {
  margin-top: -40px;
}

.dropdown-toggle::after {
  display: none;
}
.fill-button-menu{
  padding: 6px 8px;
  border-radius: 10px;
  background: #000;
  border: 1px solid #000;
  color: #fff;
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
}
.fill-button-menu:hover{
  background: transparent;
    color: #000;
}
.action_btn{
  
  background: #000;
  border: 1px solid #000;
  color: #fff;
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  padding: 6px 25px;
  border-radius: 5px;
  font-weight: 500;
  line-height: 26px;
  position: relative;
    overflow: hidden;
    transition: 0.3s;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    z-index: 99;
    text-transform: capitalize;
} 
.action_libtn{
  padding: 6px 25px;
  border-radius: 5px;
  background: #fff;
  border: 1px solid #000;
  color: #000;
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 26px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  transition: 0.3s;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  z-index: 99;
  text-transform: capitalize;
} 
.action_btn:before {
  content: '';
  display: inline-block;
  width: 0;
  height: 100%;
  background-color: #fff;
  position: absolute;
  z-index: -1;
  transition: 0.3s;
  width: 0;
    height: 0;
    border-radius: 5px;
}

.action_btn:hover:before {
  width: 100%;
  height: 100%;
}

.action_btn:hover {
  background: #fff;
  color: #000;
}
.action_libtn:before {
  content: '';
  display: inline-block;
  width: 0;
  height: 100%;
  background-color: #000;
  position: absolute;
  z-index: -1;
  transition: 0.3s;
  width: 0;
    height: 0;
    border-radius: 5px;
}

.action_libtn:hover:before {
  width: 100%;
  height: 100%;
}

.action_libtn:hover {
  background: #000;
  color: #fff;
}
.apply_btn {
  padding: 10px 35px;
  border-radius: 10px;
  background: #000;
  border: 1px solid #000;
  color: #fff;
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 26px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  transition: 0.3s;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  z-index: 99;
  text-decoration: none !important;
  text-transform: capitalize;
}
.apply_btn:before{
  content: '';
  display: inline-block;
  width: 0;
  height: 100%;
  background-color: #fff;
  position: absolute;
  z-index: -1;
  transition: 0.3s;
  width: 0;
    height: 0;
    border-radius: 5px;
}
.apply_btn:hover:before{
  width: 100%;
  height: 100%;
}

.apply_btn:hover {
  background: transparent;
  color: #1c1c1c;
}
.apply_ltbtn {
  padding: 10px 35px;
  border-radius: 10px;
  background: #fff;
  border: 1px solid #000;
  color: #000;
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 26px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  transition: 0.3s;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  z-index: 99;
  text-decoration: none !important;
  text-transform: capitalize;
}
.apply_ltbtn:before{
  content: '';
  display: inline-block;
  width: 0;
  height: 100%;
  background-color: #000;
  position: absolute;
  z-index: -1;
  transition: 0.3s;
  width: 0;
    height: 0;
    border-radius: 5px;
}
.apply_ltbtn:hover:before{
  width: 100%;
  height: 100%;
}

.apply_ltbtn:hover {
  background: #000;
  color: #fff;
}
small{
  font-size: 13px;
  font-weight: 400;
}
button:disabled {
  background-color: #F6F6F6;
  color: #d4d2d2;
  border-color: #d4d2d2;
  cursor: no-drop;
}
.ourclient .swiper-wrapper{
  justify-content: center;
}
.lablhdng{
  font-size: 15.5px;
  display: block;
}
.wrtsmall{
  display: block;
    font-size: 13px;
    font-weight: 500;
    color: #636365;
    opacity: .8;
}
.tab-content .swiper-button-next, .tab-content .swiper-button-prev{
  color: #1c1c1c;
}
.css-hlgwow{
  font-size: 14px;
}
.css-13cymwt-control{
  border: 1px solid #ced4da !important;
  width: 100%;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.5;
    appearance: none;
    border-radius: .25rem !important;
    min-height: auto !important;
}
@media(min-width:1025px) and (max-width:1280px){
  .prod-slider .swiper-button-prev{
    left: -55px !important;
  }
  .prod-slider .swiper-button-next{
    right: -55px !important;
  }
}
@media (min-width: 768px) and (max-width: 1024px) {
  .apply_ltbtn, .apply_btn{
    padding: 10px 17px;
  }
  .action_libtn, .action_btn{
    padding: 6px 13px;
  }
}
@media(max-width:767px){
  .download_csv {
    padding: 0 10px;
  }
}